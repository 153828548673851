import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import OnHold from '../views/OnHold.vue';
import ProductSalesReport from '../views/ProductSalesReport.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/en-espera',
    name: 'OnHold',
    component: OnHold
  },
  {
    path: '/productos',
    name: 'ProductSalesReport',
    component: ProductSalesReport
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
