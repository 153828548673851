<template>
	<div class="card mb-4 order-card">
		<div class="card-header" :class="order.status">
			<div class="order-number-cell">
				<div class="title">Pedido Nº</div>
				<div class="order-number">{{ order.id }}</div>
			</div>
			<div class="order-status-icon-cell">
				<IconPackaging></IconPackaging>
			</div>
			<div class="order-status-cell">
				<div class="title">Estado del pedido</div>
				<div class="order-status-text">{{ status_message }}</div>
			</div>
		</div>
		<div class="card-body">
			<div class="date-buyer-info">
				<div class="row">
					<div class="col">
						Fecha de compra: <span>{{ order.date_created }}</span>
					</div>
					<div class="col">
						Comprado por: <span>{{ order.billing_first_name }} {{ order.billing_last_name }} (Tel: {{order.billing_phone }})</span>
					</div>
				</div>
			</div>
			<div class="order-contents">
				<div class="order-content-cell">
					<h4>Productos del pedido ({{ order.products_total }})</h4>
					<b-progress class="mt-2">
						<b-progress-bar :value="order_products_packaged_amount" :label="`${(order.products_packaged * 100 / order.products_total).toFixed(2)}%`" :variant="order_progressbar_variant" show-progress :striped="order_progressbar_strips" :animated="order_progressbar_animation" ></b-progress-bar>
					</b-progress>
					<div class="product" v-for="(product, product_index) in order.products" v-bind:key="product_index">
						<div class="image-container">
							<img :src="product.image_url" alt="" class="img-thumbnail">
						</div>
						<div class="product-info">
							{{ product.name }}
							<div class="amounts">
								<div class="purchased">
									<small>Cantidad</small><br>
									<span>{{ product.quantity }}</span>
								</div>
								<div class="packaged">
									<small>Empaquetados</small>
									<b-form-spinbutton v-model="product.packaged" min="0" :max="product.quantity" @change="update_packaged_amount($event,product.id, product_index)" :disabled="order_fields_disabled_status"></b-form-spinbutton>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="order-content-cell">
					<h4>Muestras</h4>
					<p><small>Escribe las muestras separadas por coma.</small></p>
					<b-form-textarea v-model="order_samples" id="samples" placeholder="Muestras añadidas al pedido." rows="6" :disabled="order_fields_disabled_status"></b-form-textarea>
				</div>
				<div class="order-content-cell">
					<h4>Dirección de envío</h4>
					<p><strong>Destinatario:</strong> <br>{{ order.shipping_first_name }} {{ order.shipping_last_name }}</p>
					<p><strong>Dirección:</strong></p>
					<div v-if="order.shipping_address_1">{{ order.shipping_address_1 }}</div>
					<div v-if="order.shipping_address_2">{{ order.shipping_address_2 }}</div>
					<div v-if="order.shipping_city">{{ order.shipping_city }}</div>
					<div v-if="order.shipping_state">{{ order.shipping_state }}</div>
					<div v-if="order.shipping_country">{{ order.shipping_country }}</div>
					<div v-if="order.shipping_postcode">{{ order.shipping_postcode }}</div>
				</div>
			</div>
			<div class="order-voucher-info">
				<div class="row">
					<div class="col">
						<h4>Nota del usuario</h4>
					</div>
				</div>
				<div class="row">
					<div class="col">
						{{ order_note }}
					</div>
				</div>
			</div>
			<div class="order-voucher-info">
				<div class="row">
					<div class="col">
						<h4>Comprobante de pago</h4>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<label class="" :for="'voucher_type_' + order.id">Tipo de comprobante</label>
						<select v-model="order_voucher_type" class="form-control" :id="'voucher_type_' + order.id" :disabled="order_fields_disabled_status">
							<option value="">Selecciona el tipo de comprobante</option>
							<option value="Boleta">Boleta</option>
							<option value="Factura">Factura</option>
						</select>
					</div>
					<div class="col">
						<div class="form-group">
							<label :for="'voucher_number_' + order.id">Número del comprobante</label>
							<input type="text" class="form-control" :id="'voucher_number_' + order.id" placeholder="Coloca el número del comprobante" :value="order.voucher_number" :disabled="order_fields_disabled_status">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<div class="row">
				<div class="col"></div>
				<div class="col">
					<div class="text-right">
						<b-button size="lg" :variant="next_button_variant" @click="processOrder(order.id)" :disabled="next_button_disabled_status" style="min-width: 180px;">
							<span v-if="is_processing"><font-awesome-icon icon="spinner" spin /></span>
							<span v-else>{{ next_button_text }}</span>
						</b-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import IconPackaging from '@/components/icons/packaging.vue';
import axios from 'axios';
export default {
  	name: 'Order',
  	components: {
		IconPackaging
	},
	data() {
		return{
			status_message: '',
			order_products_packaged_amount: 0,
			order_progressbar_variant: '',
			order_progressbar_animation: false,
			order_progressbar_strips: false,
			next_button_variant: '',
			next_button_text: '',
			next_button_disabled_status: false,
			order_fields_disabled_status: false,
			order_samples: '',
			is_processing: false,
			order_voucher_type: '',
			order_note: ''
		}
	},
	props: [
		'order'
	],
	created() {
		var app = this;
		app.status_message = app.getStatusMessage(app.order.status);
		app.order_products_packaged_amount = app.getPackagedPercentage();
		app.order_samples = app.order.order_samples_final;

		// Verificar el tipo de comprobante y seleccionarlo.
		if(app.order.voucher_type != 'Boleta' || app.order.voucher_type != 'Factura'){
			app.order_voucher_type = '';
		}

		// Verificar si el usuario dejó una nota en el pedido y mostrar el texto.
		if(app.order.order_notes != ''){
			app.order_note = app.order.order_notes;
		}
		else{
			app.order_note = 'El usuario no dejó notas en el pedido.';
		}
	},
	methods: {
		update_packaged_amount(amount, product_id, product_index){
			var app = this;
			axios.get('https://encremadas.com/wp-json/lpxwcom/v1/updateorderproduct?product_id=' + product_id + '&product_amount=' + amount + '&order_id=' + app.order.id)
			.then((response)  =>  {
				if(response.data == 'updated'){
					console.log('Se actualizó el producto ' + product_id + ' a la cantidad de ' + amount + ' del número de pedido ' + app.order.id + '.');
					app.order.products[product_index].packaged = amount;
					app.order.products_packaged = 0;
					app.order.products.forEach(function(value, index) {
						app.order.products_packaged += value.packaged;
					});
					app.status_message = app.getStatusMessage(app.order.status);

					app.order_products_packaged_amount = app.getPackagedPercentage();
				}
				else{
					console.warn('No se pudo actualizar el producto ' + product_id + ' a la cantidad de ' + amount + ' del número de pedido ' + app.order.id + '.');
				}					
			},(error) => {
				alert('Se produjo un error al momento de actualizar la cantidad empaquetada del producto. Inténtalo nuevamente.');
			});
		},
		getPackagedPercentage(){
			var app = this;
			return (app.order.products_packaged * 100) / app.order.products_total;
		},
		processOrder(order_id){
			var app = this;
			var packaged_percentage = app.getPackagedPercentage();
			
			app.is_processing = true;
			app.next_button_disabled_status = true;
			app.order_fields_disabled_status = true;

			if(packaged_percentage < 100){
				axios.get('https://encremadas.com/wp-json/lpxwcom/v1/updateordersamples?order_samples=' + app.order_samples + '&order_id=' + app.order.id)
					.then((response)  =>  {
						if(response.data == true || Number.isInteger(response.data) ){
							console.log('Muestras actualizadas para el pedido ' + app.order.id + '.');
							alert('El pedido ' + app.order.id + ' se guardó correctamente.');
						}
						else{
							console.warn('No se pudieron actualizar las muestras del pedido ' + app.order.id + '.');
							alert('El pedido ' + app.order.id + ' se guardó correctamente.');
						}
						app.is_processing = false;
						app.next_button_disabled_status = false;
						app.order_fields_disabled_status = false;
					},(error) => {
						app.is_processing = false;
						app.next_button_disabled_status = false;
						app.order_fields_disabled_status = false;
						alert('Se produjo un error al momento de actualizar las muestras del pedido ' + app.order.id + '. Inténtalo nuevamente.');
					});
			}
			else{				
				var order_process_permit = false;
				var samples_check = window.confirm('¿Despachar el pedido sin muestras?');
				if(typeof(app.order_samples) === 'undefined' || app.order_samples === null){
					
					if (samples_check) {
						order_process_permit = true;
					}
					else{
						order_process_permit = false;
						app.is_processing = false;
						app.next_button_disabled_status = false;
						app.order_fields_disabled_status = false;
					}
				}
				else if(app.order_samples.trim() == ''){
					if (samples_check) {
						order_process_permit = true;
					}
					else{
						order_process_permit = false;
						app.is_processing = false;
						app.next_button_disabled_status = false;
						app.order_fields_disabled_status = false;
					}
				}
				else{
					order_process_permit = true;
				}

				if(order_process_permit == true){

					var order_process = window.confirm('¡Atención! El pedido ' + app.order.id + ' será actualizado a Completado. ¿Deseas continuar?');

					if(order_process){

						console.log('Order process: ' + app.$parent.home_user_hash);
						axios.get('https://encremadas.com/wp-json/lpxwcom/v1/updateordersamples?order_samples=' + app.order_samples + '&order_id=' + app.order.id)
						.then((response)  =>  {
							if(response.data == true || Number.isInteger(response.data) ){
								console.log('Muestras actualizadas para el pedido ' + app.order.id + '.');
							}
							else{
								console.warn('No se pudieron actualizar las muestras del pedido ' + app.order.id + '.');
							}
							axios.get('https://encremadas.com/wp-json/lpxwcom/v1/updateorderstatus?order_status=completed&order_id=' + app.order.id + '&user=' + app.$parent.home_user_hash)
								.then((response)  =>  {
									if(response.data == true){
										console.log('El pedido ' + order_id + ' pasó a despacho.');
										app.order.status = 'completed';
										app.status_message = app.getStatusMessage(app.order.status);
										app.$parent.alert_message = 'El pedido ' + order_id + ' pasó a despacho.';
										app.$parent.isAlertMesage = true;
										app.$parent.updateListing('wc-processing');
									}
									else{
										console.warn('No se pudo cambiar el estado del pedido ' + order_id + '.');
									}
									app.is_processing = false;
									app.next_button_disabled_status = false;
									app.order_fields_disabled_status = false;
									console.log(response.data);
								},(error) => {
									app.is_processing = false;
									app.next_button_disabled_status = false;
									app.order_fields_disabled_status = false;
									alert('Se produjo un error al momento de actualizar el estado del pedido. Inténtalo nuevamente.');
								});
						},(error) => {
							app.is_processing = false;
							app.next_button_disabled_status = false;
							app.order_fields_disabled_status = false;
							alert('Se produjo un error al momento de actualizar las muestras del pedido ' + app.order.id + '. Inténtalo nuevamente.');
						});
					}
					else{
						app.is_processing = false;
						app.next_button_disabled_status = false;
						app.order_fields_disabled_status = false;
					}
				}
				
			}
		},
		getStatusMessage(status){
			var app = this;
			var result = '';
			app.order_fields_disabled_status = true;
			switch(status){
				case 'pending':
					result = 'Pendiente de selección de método de pago';
					app.next_button_text = 'Pagado';
					app.next_button_variant = 'success';
					app.order_progressbar_variant = 'secondary';
					app.order_progressbar_animation = false;
					app.order_progressbar_strips = false;
					break;
				case 'cancelled':
					result = 'Cancelado';
					app.next_button_text = 'Cancelado';
					app.next_button_variant = 'secondary';
					app.order_progressbar_variant = 'secondary';
					app.order_progressbar_animation = false;
					app.order_progressbar_strips = false;
					break;
				case 'refunded':
					result = 'Devuelto';
					app.next_button_text = 'Devuelto';
					app.next_button_variant = 'secondary';
					app.order_progressbar_variant = 'secondary';
					app.order_progressbar_animation = false;
					app.order_progressbar_strips = false;
					break;
				case 'on-hold':
					result = 'En espera de confirmación de pago';
					app.next_button_text = 'Procesar';
					app.next_button_variant = 'success';
					app.order_progressbar_variant = 'warning';
					app.order_progressbar_animation = false;
					app.order_progressbar_strips = false;
					break;
				case 'failed':
					result = 'Fallido';
					app.next_button_text = 'Fallido';
					break;
				case 'processing':
					app.next_button_text = 'Guardar';
					app.next_button_variant = 'success';
					app.order_progressbar_variant = 'success';
					app.order_progressbar_animation = true;
					app.order_progressbar_strips = true;
					app.order_fields_disabled_status = false;
					if(app.order.products_packaged == 0){
						result = 'Iniciar empaquetado';
					}
					else if(app.order.products_packaged > 0 && app.order.products_packaged < app.order.products_total){
						result = 'Falta empaquetar ' + (app.order.products_total - app.order.products_packaged) + ' producto(s)';
					}
					else{
						result = '¡Listo! Despachar el pedido';
						app.next_button_text = 'Despachar';
						app.next_button_variant = 'primary';
						app.order_progressbar_variant = 'primary';
						app.order_progressbar_animation = false;
						app.order_progressbar_strips = false;
					}
					break;
				case 'completed':
					result = 'Pedido completado y despachado';
					app.next_button_variant = 'primary';
					app.next_button_text = 'Despachado';
					app.order_progressbar_variant = 'primary';
					app.order_progressbar_animation = false;
					app.order_progressbar_strips = false;
					app.next_button_text = '';
					break;
			}
			return result;
		}
	}
}
</script>