<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 style="margin-top: 80px;">Pedidos en Proceso <button type="button" class="btn btn-primary float-right" @click="updateListing('wc-processing')"><b-sync variant="primary" label="Spinning"></b-sync> Actualizar listado</button></h1>
          <p>Los Pedidos en Proceso son aquellos que ya fueron pagados y se debe empezar con el proceso de empaquetado.</p>
          <div v-if="isAlertMesage" class="alert alert-warning alert-dismissible fade show" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>{{ alert_message }}</strong> 
          </div>
        </div>
      </div>
      <div class="row" v-if="isFetchingData == false">
        <div class="col">
          <div :index="index" :value="value" v-for="(value, index) in orders" v-bind:key="index">
            <OrderCard :order="value"></OrderCard>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col">
          <b-spinner variant="primary" label="Spinning"></b-spinner><span style="margin-left: 30px;">Cargando los pedidos. Por favor, espera un momento.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import OrderCard from '@/components/OrderCard.vue';
import axios from 'axios';
export default {
  name: 'Home',
  components: {
    OrderCard
  },
	data() {
		return {
      orders: [],
			isFetchingData: true,
      isAlertMesage: false,
      alert_message: '',
      home_user_hash: ''
		}
	},
	created() {
    var app = this;
    app.home_user_hash = app.$parent.user_hash;
		app.loadOrders('wc-processing');
	},
	methods: {
		loadOrders(order_status) {
			var app = this;
      console.log('Load Orders: ' + app.$parent.user_hash);
			app.isFetchingData = true;
			axios.get('https://encremadas.com/wp-json/lpxwcom/v1/getorders?status=' + order_status + '&user=' + app.home_user_hash)
			.then((response)  =>  {
				app.orders = response.data;
				app.isFetchingData = false;
      },(error) => {
				app.isFetchingData = false;
			});
    },
    updateListing(order_status){
      var app = this;
      app.loadOrders(order_status);
    }
	}
}
</script>
