<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" type="dark" variant="dark" id="nav" fixed="top">
        <div class="container">
            <b-navbar-brand href="/">Pedidos Encremadas</b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <b-nav-item to="/">En Proceso</b-nav-item>
                <b-nav-item to="/en-espera">En Espera</b-nav-item>
                <b-nav-item to="/productos">Productos</b-nav-item>
              </b-navbar-nav>
            </b-collapse>
        </div>
      </b-navbar>
    </div>
    <keep-alive>
      <router-view v-if="password_permit == true" />
    </keep-alive>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'App',
	data() {
		return{
			password_permit: false,
      user_hash: false
		}
	},
	created() {
		var app = this;
		app.verify_password();
	},
	methods: {
		verify_password(){
      var app = this;
      //var username = prompt('Coloca tu nombre de usuario', ' ');
      var username = prompt('Coloca tu nombre de usuario', '');
      var password = prompt('Coloca tu contraseña', '');

      var bodyFormData = new FormData();
      bodyFormData.append('username', username);
      bodyFormData.append('password', password);

      axios({
        method: "post",
        url: "https://encremadas.com/wp-json/lpxwcom/v1/login",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response)  =>  {
				app.user_hash = response.data;
        console.log('Login: ' + app.user_hash);
        if(app.user_hash != false){
          app.password_permit = true;
        }
      },(error) => {
				alert('Se produjo un error.');
			});
    }
  }
}
</script>