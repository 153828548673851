<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 style="margin-top: 80px;">Productos vendidos</h1>
          <p>Listado de productos vendidos dependiendo del rango de fechas.</p>
        </div>
      </div>
	  <div class="row" style="margin-top: 30px;">
		  <div class="col-md-4">
			  	<label for="start_date_field">Fecha de inicio</label>
    			<b-form-datepicker id="start_date_field" v-model="start_date" class="mb-2"></b-form-datepicker>
		  </div>
		  <div class="col-md-4">
			  	<label for="end_date_field">Fecha de finalización</label>
    			<b-form-datepicker id="end_date_field" v-model="end_date" class="mb-2"></b-form-datepicker>
		  </div>
		  <div class="col-md-2">
			  	<label for="brands_field">Marcas</label>
			 	<b-form-select id="brands_field" v-model="brand_selected" :options="brand_options"></b-form-select>
		  </div>
		  <div class="col-md-2">
			  <button type="button" class="btn btn-primary btn-block" @click="updateListing()" style="margin-top: 31px;">Actualizar</button>
		  </div>
	  </div>
      <div class="row" v-if="isFetchingData == false" style="margin-top: 20px; margin-bottom: 50px;">
        <div class="col" v-if="items.length > 0">
			<b-table
				:items="items"
				:fields="fields"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				responsive="sm"
				></b-table>
        </div>
		<div class="col" v-else>
			No se encontraron resultados.
		</div>
      </div>
      <div class="row" v-else style="margin-top: 20px;">
        <div class="col">
          <b-spinner variant="primary" label="Spinning"></b-spinner><span style="margin-left: 30px;">Cargando listado de productos vendidos. Por favor, espera un momento.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
export default {
 	name: 'ProductSalesReport',
	data() {
		return {
      		products: [],
			isFetchingData: true,
			sortBy: 'age',
			sortDesc: false,
			fields: [
				{ key: 'number', label: '#', sortable: true },
				{ key: 'id', sortable: true },
				{ key: 'sku', sortable: true },
				{ key: 'name', label: 'Nombre', sortable: true },
				{ key: 'amount', label: 'Cantidad', sortable: true },
				{ key: 'brand', label: 'Marca', sortable: true },
			],
			items: [],
			start_date: '',
			end_date: '',
			brands: [],
			brand_selected: null,
			brand_options: []
		}
	},
	created() {
		var app = this;

		var today = new Date();
		var today_dd = String(today.getDate()).padStart(2, '0');
		var today_mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var today_yyyy = today.getFullYear();

		var last_week_day = new Date(today.setDate(today.getDate() - 7));
		var last_week_dd = String(today.getDate()).padStart(2, '0');
		var last_week_mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var last_week_yyyy = today.getFullYear();

		app.start_date = last_week_yyyy + '-' + last_week_mm + '-' + last_week_dd;
		app.end_date = today_yyyy + '-' + today_mm + '-' + today_dd;

		app.loadBrands();
		app.loadProductSales();
	},
	methods: {
		loadProductSales() {
			var app = this;
			app.isFetchingData = true;
			axios.get('https://encremadas.com/wp-json/lpxwcom/v1/getsalesreport?start_date=' + app.start_date + '&end_date=' + app.end_date + '&brand=' + app.brand_selected)
				.then((response)  =>  {
					console.log(app.brand_selected);
					app.products = response.data;
					app.createProductListing();
				},(error) => {
					app.isFetchingData = false;
				});
		},
		createProductListing(){
			var app = this;
			var counter = 0;
			app.items = [];
			$.each(app.products, function( index, value ) {
				counter++;
				app.items.push({
					number: counter, 
					id: index, 
					sku: value.sku ,
					name: value.name,
					amount: value.sales,
					brand: value.brand
				});
			});
			app.isFetchingData = false;
		},
		loadBrands(){
			var app = this;
			app.brand_options = [];
			axios.get('https://encremadas.com/wp-json/lpxwcom/v1/getterms?name=marca')
				.then((response)  =>  {
					app.brands = response.data;
					$.each(app.brands, function( index, value ) {
						app.brand_options.push({
							value: value, 
							text: value
						});
						app.brand_selected = 'Todas';
					});
				},(error) => {
					app.isFetchingData = false;
				});
		},
		updateListing(order_status){
			var app = this;
			app.loadProductSales();
		}
	}
}
</script>
