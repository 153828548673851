import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import router from './router';

window.Vue = require('vue');
window.$ = require('jquery');
window.JQuery = require('jquery');

library.add(faSpinner);

// Import styles.
import '../src/assets/app.scss';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// Iconos SVG.
Vue.component(
  'IconPackaging',
  require('../src/components/icons/packaging.vue')
);

// Iconos de FontAwesome.
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
